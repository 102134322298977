@import '../../../../styles/variables';

.options {
    padding: 20px;
    background: url("../../assets/lines-2.jpg");
    background-position: center -46px;
    background-repeat: no-repeat;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
}

.title {
    font-family: "Lexend Deca", sans-serif;
    font-size: 24px;
    text-transform: uppercase;
    margin: 0;
}

.titleTxt {
    font-size: 14px;
    font-family: "Lexend Deca", sans-serif;
    color: #7e7e7e;
    margin-bottom: 10px;
}

.address {
    font-family: "Lexend Deca", sans-serif;
    font-size: 14px;
    margin: 0;
    color: #ccc;
}

.opts {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
}

.btnBox {
    margin-top: 18px;

    button {
        display: flex;
        height: 68px;
        border-radius: 2px;
        background: #F8F8F8;
        box-shadow: 0 14px 44px 0 rgba(0, 0, 0, 0.14);
        padding: 16px 22px;
        width: 100%;
        text-transform: uppercase;
        align-items: center;
        justify-content: space-between;

        color: #000;
        font-size: 16px;
        font-weight: 700;
    }
}

