.permissions {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 20px 0 20px;
    background: url("../../assets/lines-2.jpg");
    background-position: center -46px;
    background-repeat: no-repeat;
    background-size: 100%;
}

.title {
    font-family: "Lexend Deca", sans-serif;
    font-size: 24px;
    text-transform: uppercase;
    margin: 0 0 5px 0;
}
.address {
    font-family: "Lexend Deca", sans-serif;
    font-size: 14px;
    margin: 0;
    color: #ccc;
}

.titleTxt {
    font-size: 14px;
    font-family: "Lexend Deca", sans-serif;
    color: #7e7e7e;
    margin-bottom: 10px;
}

.wrap {
    display: flex;
    flex: 1;
    align-items: flex-end;
}

.box {
    margin-top: 50px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 14px 44px 0 rgba(0, 0, 0, 0.14);
    padding: 20px 20px 0 20px;
    color: #000;
    position: relative;

    h5 {
        margin: 0 0 12px;
        font-size: 18px;
        text-align: center;
    }

    ul {
        margin: 12px 0 0 0;
        padding: 0 0 0 14px;
        font-size: 16px;
        line-height: 1.2;

        li {
            padding-bottom: 10px;
        }
    }

    .icon {
        text-align: center;
        margin-bottom: 18px;
    }

    .subBlock {
        position: absolute;
        left: 13px;
        right: 13px;
        height: 16px;
        top: -16px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background: #c4c4c4;
    }

    .bottom {
        text-align: center;
        margin-top: 14px;
        border-top: 1px solid #EAEAEA;
        height: 40px;
        display: flex;
        margin-left: -20px;
        margin-right: -20px;

        > div {
            width: 50%;
        }

        .line {
            border-right: 1px solid #EAEAEA;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                width: 86px;
                height: 9px;
                background: #D9D9D9;
            }
        }

        .allow {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #A0A0A0;
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.nextBtnBox {
    background-color: #f6f6f6;
    margin-top: -40px;
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 60px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    button {
        height: 50px;
        border-radius: 2px;
        background: #000;
        width: 100%;
        color: #FFF;
        font-size: 16px;
        font-weight: 700;
    }
}

.errTxt {
    padding-bottom: 20px;
}
