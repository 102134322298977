@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz@0,6..12;1,6..12&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,700;1,6..12,700&display=swap');

/* Primary Styles */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    height: 100%;
    box-sizing: border-box;
    background: transparent;
}

body {
    margin: 0;
    padding: 0;
    background-image: linear-gradient(#1b1b1b, #000);
    background-attachment: fixed;
    background-size: cover;
    background-color: #1b1b1b;
    color: #fff;
    font-family: "Nunito Sans", sans-serif;
    min-height: 100%;
    display: flex;
    flex-direction: column;


    div {
        font-weight: normal;
    }
}

em {
    font-style: normal;
}

p {
    font-weight: normal;
    margin-top: 0;
}

a {
    text-decoration: none;
}


button {
    background-color: transparent;
    background-image: none;
    border: none;
    cursor: pointer;
    color: inherit;

    &:focus {
        outline: none;
    }
}

#root {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.Toastify__toast-container {
    padding: 5px;
}

.Toastify__toast--error {
    border: 1px solid #e74c3c;
}

.Toastify__toast--success {
    border: 1px solid #07bc0c;
}

.Toastify__toast--info {
    border: 1px solid #3498db;
}

.Toastify__toast--warning {
    border: 1px solid #f1c40f;
}

.Toastify__close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: -8px;
    margin-top: -6px;
    padding-left: 2px;

    svg {
        width: 18px;
        height: auto;
    }
}
