.root {
    min-width: 100%;
    padding-top: 58px;
}

.searchBar {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    border-top: 1px solid #383838;
    border-bottom: 1px solid #383838;
    background: #1a1a1a;
}


.backBox {
    border-left: 1px solid #383838;
    min-width: 58px;

    button {
        height: 58px;
        width: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.field {
    width: 100%;
    display: flex;
    align-items: center;

    svg {
        fill: #fff;
        width: 20px;
        height: auto;
    }

    input {
        display: block;
        height: 58px;
        padding: 16px;
        line-height: 58px;
        background: transparent;
        border: none;
        width: 100%;
        color: #fff;
        font-size: 15px;

        &:focus {
            outline: none;
        }
    }

    > span {
        min-width: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .actionBtn {
        height: 58px;
        width: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.list {
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        padding: 15px 18px;
    }

    button {
        font-size: 22px;
        color: #fff;
        display: block;
        width: 100%;
        text-align: left;
    }

    .callBtn {
        display: none;
        align-items: center;
        justify-content: space-between;
        background: #000;
        color: #fff;
        height: 58px;
        font-size: 20px;
        padding: 15px 20px;
        margin-bottom: 10px;

        svg {
            fill: #fff;
            width: 22px;
            height: auto;
        }
    }
}

.itemBtn {
    padding: 15px 0;

    &:focus {
        outline: none;
        background: transparent;
    }
}

.active {
    background: #fff;

    .itemBtn {
        color: #000
    }

    .callBtn {
        display: flex;
    }
}


.loading {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    padding:  0 20px;
    text-align: center;
    display: flex;
    justify-content: center;
}
