.root {
    position: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(#1b1b1b, #000);
    left: 0;
    top: 0;
    width: 100%;
    padding: 0 0 34px 0;
    z-index: 10;
}

.title {
    margin-top: 20%;
    text-align: center;
    font-family: "Lexend Deca", sans-serif;

    p {
        font-size: 14px;
        color: #8c8c8c;
        margin-bottom: 14px;
    }

    span {
        color: #fff;
        font-size: 24px;
    }
}

.icon {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 30%;

    svg {
        width: 84px;
        height: auto;
    }
}

.unlocked {
    text-transform: uppercase;
    font-family: "Lexend Deca", sans-serif;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 16px;
        margin-left: 4px;
    }
}
