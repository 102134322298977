.scanPackage {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 58px;

    header {
        height: 58px;
        display: flex;
        justify-content: center;

        p {
            width: 100%;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        button, > div {
            height: 58px;
            width: 58px;
            min-width: 58px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                fill: #fff;
            }
        }
    }
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
}

.camera {
    video {
        position: fixed;
        left: 0;
        top: 58px;
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
    }
}

.actionsBox {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;

    .label {
        text-align: center;
        color: #fff;
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 20px;
    }

    .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .captureBtnBox {
        min-width: 84px;
        height: 84px;
        background: rgba(#383838, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .captureBtn {
        min-width: 72px;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        padding: 0;

        svg {
            width: 32px;
            height: auto;
            fill: #000;
        }
    }

    .submitBtn {
        min-width: 56px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00D147;

        &:disabled {
            opacity: 0;
        }

        svg {
            width: 18px;
            height: auto;
            fill: #fff;
        }
    }

    .preview {
        min-width: 56px;

        .imgBox {
            position: relative;
            width: 46px;
            height: 56px;
            background: #000;

            span {
                position: absolute;
                width: 28px;
                height: 28px;
                right: -14px;
                bottom: -14px;
                border-radius: 50%;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                font-size: 14px;
                font-weight: 600;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}


.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#1b1b1b, #000);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
    padding-top: 20%;
    padding-bottom: 50%;

    .loadingTitle {
        color: #fff;
        text-align: center;
        font-family: "Lexend Deca", sans-serif;
        font-size: 24px;
        font-weight: 400;
        margin: 0;
    }

    .loadingSubTitle {
        color: #8c8c8c;
        font-family: "Lexend Deca", sans-serif;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 18px;
    }

    .packageWrap {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .packageBox {
        width: 109px;
        height: 109px;
        position: relative;

        span {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
            border: 10px solid #0f0f0f;
        }

        svg {
            width: 109px;
            height: 109px;
        }
    }

    .uploadDoneBtn {
        height: 68px;
        border-radius: 2px;
        background: #fff;
        position: fixed;
        left: 18px;
        bottom: 30px;
        right: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
    }
}

.dotsLoading {
    display: inline-flex;
    align-items: center;
    margin-left: 2px;

    .dot {
        width: 5px;
        height: 5px;
        margin: 0 2px;
        background-color: #fff;
        border-radius: 50%;
        opacity: 0;
        animation: blink 2s infinite;
    }
    .dot:nth-child(1) {
        animation-delay: 0s;
    }
    .dot:nth-child(2) {
        animation-delay: 0.5s;
    }
    .dot:nth-child(3) {
        animation-delay: 1s;
    }

    @keyframes blink {
        0%, 100% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
    }
}
