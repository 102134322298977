.loader {
    position: relative;
    width: 24px; /* Adjust this to the desired size */
    height: 24px; /* Adjust this to the desired size */
}
.loader .dot {
    position: absolute;
    width: 21%; /* Dot size relative to the loader size */
    height: 21%; /* Dot size relative to the loader size */
    background-color: #fff;
    border-radius: 50%;
}
.loader .spinner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: center;
    animation: spinner 2s linear infinite;
}

.dot:nth-child(1) { top: 50%; left: 0; transform: translate(-50%, -50%); }
.dot:nth-child(2) { top: 15%; left: 15%; transform: translate(-50%, -50%); }
.dot:nth-child(3) { top: 0; left: 50%; transform: translate(-50%, -50%); }
.dot:nth-child(4) { top: 15%; left: 85%; transform: translate(-50%, -50%); }
.dot:nth-child(5) { top: 50%; left: 100%; transform: translate(-50%, -50%); }
.dot:nth-child(6) { top: 85%; left: 85%; transform: translate(-50%, -50%); }
.dot:nth-child(7) { top: 100%; left: 50%; transform: translate(-50%, -50%); }
.dot:nth-child(8) { top: 85%; left: 15%; transform: translate(-50%, -50%); }

@keyframes spinner {
    100% { transform: rotate(360deg); }
}
