.root {
    position: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(#1b1b1b, #000);
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
}

.content {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.message {
    padding: 20px;
    font-size: 18px;
    color: #000;
    background: #fff;
    border-radius: 4px;
    text-align: center;
}

.actions {
    padding: 20px 20px 8px;

    button {
        display: flex;
        height: 68px;
        border-radius: 2px;
        background: #F8F8F8;
        box-shadow: 0 14px 44px 0 rgba(0, 0, 0, 0.14);
        padding: 16px 22px;
        width: 100%;
        text-transform: uppercase;
        align-items: center;
        justify-content: space-between;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 16px;
    }

    button:first-child {
        background-color: #3500ff;
        color: #fff;

        svg {
            fill: #fff;
        }
    }
}
