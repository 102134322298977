.root {
    min-width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    header {
        height: 58px;
        display: flex;
        justify-content: center;

        p {
            width: 100%;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        button, > div {
            height: 58px;
            width: 58px;
            min-width: 58px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                fill: #fff;
            }
        }
    }
}

.content {
    padding: 20px;
    display: flex;
    margin-top: 30px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.field {
    border-bottom: 1px solid #2c2c2c;
    padding-bottom: 14px;
    width: 100%;

    input {
        background-color: transparent;
        border: 0;
        color: #fff;
        text-align: center;
        font-family: "Lexend Deca", sans-serif;
        font-size: 44px;
        letter-spacing: 12px;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
}


.btn {
    margin-top: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background: #FFF;
    height: 68px;
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;

    .loader {
        margin-left: 12px;
    }
}
