@import '../../../../styles/variables';

.header {
    margin-bottom: 50px;

    img {
        display: block;
        height: 18px;
    }
}
