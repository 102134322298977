@import '../../../../styles/variables';

.capturePhoto {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-image: linear-gradient(#1b1b1b, #000);
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        position: absolute;
        left: 0;
        top: 0;
        margin-bottom: 0;
        display: block;
        width: 100%;
        padding: 30px 20px;
        text-align: center;
        color: #fff;
        font-size: 20px;
        font-family: "Lexend Deca", sans-serif;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), transparent);
        z-index: 1;
    }

    button {
        height: 50px;
        border-radius: 2px;
        background: #F8F8F8;
        box-shadow: 0 14px 44px 0 rgba(0, 0, 0, 0.14);
        padding: 16px 22px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
    }
}

.camera {
    flex: 1;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;

    video {
        object-fit: cover;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
    }
}

.btnBox {
    position: absolute;
    bottom: 30px;
}
